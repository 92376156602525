.login-container {
    width: 24rem;
    height: 100vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-page {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        h4{
            color: #101828;
        }
        
        .login-welcome-message-text{
            color: #919191;
            font-size: 1rem;
            font-weight: 400;
        }
        
        .login-formik-input-fields {

            input {
                width: 100%;
                border: 0.0625rem solid #D0D5DD;
                background-color: #FFFFFF;
                padding: 0.3125rem 0.625rem;
                color: #667085;
                min-height: 3rem;
            }
        
            input::placeholder{
                color: #667085;
            }
        
            input:focus{
                border-color: #D0D5DD;
                box-shadow: none;
            }

            .input-with-icon-view {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.375rem;
                flex: 1 0 0;
                min-height: 3rem;

                .input-content {
                    display: flex;
                    padding: 0rem 0.625rem;
                    align-items: center;
                    gap: 0.5rem;
                    align-self: stretch;
                    border-radius: 0.5rem;
                    border: 1px solid var(--gray-300, #D0D5DD);
                    background: var(--base-white, #FFF);
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                    svg {
                        cursor: pointer;
                    }

                    input {
                        width: 100%;
                        border: none;
                        padding: 0;
                    }

                    input:focus-visible {
                        outline: none;
                    }
                }
            }

            .error {
                color: #E66464;
                display: flex;
                font-weight: 600;
            }
            .password-field::placeholder {
                font-size: 35px;
                line-height: 4;
            }
        }

        .sign-in-button {
            width: 100%;
            background-color: #000000;
            border: #000000;
            color: #FFFFFF;
            font-size: 1rem;
            font-weight: bolder;
            min-height: 3rem;
        }

        .sign-in-button:hover {
            background-color: #2c2b2b;
        }

        .sign-in-button:active{
            background-color: #524e4e;
            border-color: #524e4e;
            color: #FFFFFF;
        }

        .forgot-password{
            font-size: 0.875rem;
            font-weight: bolder;
            cursor: pointer;
            color: #000000;
        }
    }
}