.MuiBox-root {
    .MuiListItemButton-root:hover {
        border-radius: 6.25rem;
    }

    .MuiCollapse-root {
        .MuiListItemButton-root:hover {
            color: #FFFFFF;
            background-color: #000000;
            border-radius: 6.25rem;

            .MuiListItemIcon-root {
                .submenu-item-icon {
                    stroke: #FFFFFF;
                    fill: #FFFFFF;
                }
            }
        }

        .Mui-selected {
            color: #FFFFFF;
            background-color: #000000;
            border-radius: 6.25rem;

            .MuiListItemIcon-root {
                .submenu-item-icon {
                    stroke: #FFFFFF;
                    fill: #FFFFFF;
                }
            }
        }

        .item-with-collapse {
            display: flex;
            padding: 0rem 0.5rem 0.5rem 0.5rem;
            gap: 0.25rem;
            align-self: stretch;
        }
    }
        
    .footer-navigation {
        .footer-nav-item {
            display: flex;
            width: 15.5rem;
            padding: 0.5rem 0.75rem 0.5rem 0.875rem;
            align-items: flex-start;
            gap: 2.9375rem;
            align-self: stretch;
            border: none;
            border-radius: 6.25rem;
        }

        .footer-nav-item:hover {
            border-radius: 6.25rem;
            background: var(--base-black, #000);
            border: none;

            .side-bar-footer-user-icon {
                path {
                    stroke: var(--base-white, #FFF);
                }
            }

            .side-bar-footer-accout-settings {
                color: var(--base-white, #FFF);
                font-family: Inter;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.5rem;
            }
        }

        .side-bar-footer-accout-settings {
            color: var(--gray-700, #344054);
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.5rem;
        }

        .Mui-selected {
            background: var(--base-black, #000);

            .side-bar-footer-user-icon {
                path {
                    stroke: var(--base-white, #FFF);
                }
            }

            .side-bar-footer-accout-settings {
                color: var(--base-white, #FFF);
                font-family: Inter;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.5rem;
            }
        }
    }

    .single-item-without-collapse {
        display: flex;
        padding: 0rem 1.2rem 0rem 0.5rem;
        gap: 0.25rem;
        align-self: stretch;

        a {
            padding: 0.5rem 0.75rem 0.5rem 1.05rem;
        }

        a:hover {
            color: #FFFFFF;
            background-color: #000000;
            border-radius: 6.25rem;

            .events-database-icon {
                path {
                    stroke: #FFFFFF;
                }
            }
        }

        .Mui-selected {
            color: #FFFFFF;
            background-color: #000000;
            border-radius: 6.25rem;

            .events-database-icon {
                path {
                    stroke: #FFFFFF;
                }
            }
        }
    }
}
