.forgot-password-container {
    width: 24rem;
    height: 100vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .forgot-password-page {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        h4{
            color: #101828;
        }
        
        .forgot-password-welcome-message-text{
            color: #475467;
            font-size: 1rem;
            font-weight: 400;
        }
        
        .forgot-password-formik-input-fields {
            input {
                width: 100%;
                border: 0.0625rem solid #D0D5DD;
                background-color: #FFFFFF;
                padding: 0.3125rem 0.625rem;
                color: #667085;
                min-height: 3rem;
            }
        
            input::placeholder{
                color: #667085;
                font-size: 1rem;
                font-weight: 400;
            }
        
            input:focus{
                border-color: #D0D5DD;
                box-shadow: none;
            }

            .error {
                color: #E66464;
                display: flex;
                font-weight: 600;
            }
        }

        .reset-password-button {
            width: 100%;
            background-color: #000000;
            border: #000000;
            color: #FFFFFF;
            font-size: 1rem;
            font-weight: bolder;
            min-height: 3rem;
        }

        .reset-password-button:hover {
            background-color: #2c2b2b;
        }

        .reset-password-button:active{
            background-color: #524e4e;
            border-color: #524e4e;
            color: #FFFFFF;
        }

        .back-to-login-page-view {
            .back-to-login {
                font-size: 0.875rem;
                font-weight: bolder;
                cursor: pointer;
                color: #475467;
                margin-left: 0.5rem;
            }
        }

        .success {
            color: #07bc0c;
            display: flex;
            font-weight: 600;
        }

        .error {
            color: #E66464;
            display: flex;
            font-weight: 600;
        }
    }
}