.add-user-modal-view {
    display: flex;
    justify-content: center;
    align-items: center;

    .add-user-modal {
        display: flex;
        width: 53.5rem;
        flex-direction: column;
        align-items: center;
        border-radius: 0.75rem;
        background: var(--base-white, #FFF);
        box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);

        .add-user-modal-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-self: stretch;

            .add-user-modal-header-content {
                display: flex;
                padding: 1.5rem 1.5rem 1.25rem 1.5rem;
                border-radius: 0.75rem 0.75rem 0rem 0rem;
                flex-direction: column;
                align-items: flex-start;
                gap: 1rem;
                align-self: stretch;
                background: var(--base-white, #FFF);

                .add-user-modal-header-icons-view {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    .add-user-modal-header-feature-icon {
                        display: flex;
                        width: 3rem;
                        height: 3rem;
                        padding: 0.75rem;
                        justify-content: center;
                        align-items: center;
                        border-radius: 0.625rem;
                        border: 1px solid var(--gray-200, #EAECF0);
                        background: var(--base-white, #FFF);
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    
                        .header-building-icon {
                            width: 1.5rem;
                            height: 1.5rem;
                            flex-shrink: 0;
                        }
                    }

                    .add-user-modal-header-close-button {
                        display: flex;
                        padding: 0.625rem;
                        justify-content: center;
                        align-items: center;
                        right: 1rem;
                        top: 1rem;
                        border-radius: 0.5rem;

                        .close-modal-icon {
                            cursor: pointer;
                            width: 1.5rem;
                            height: 1.5rem;
                        }
                    }

                }

                .add-user-modal-header-text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.25rem;
                    align-self: stretch;

                    .add-user-modal-header-title-text {
                        align-self: stretch;
                        color: var(--gray-900, #101828);
                        font-family: Inter;
                        font-size: 1.125rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.75rem;
                    }

                    .add-user-modal-header-info-text {
                        align-self: stretch;
                        color: var(--gray-600, #475467);
                        font-family: Inter;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1.25rem;
                    }
                }
            }
        }

        .add-user-modal-content {
            display: flex;
            padding: 0rem 1.5rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.25rem;
            align-self: stretch;

            .add-user-form {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1rem;
                align-self: stretch;

                .input-field-frame-1 {
                    display: flex;
                    align-items: flex-start;
                    gap: 1rem;
                    align-self: stretch;

                    .input-field-view {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.375rem;
                        flex: 1 0 0;
                        align-self: stretch;

                        .input-with-label {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 0.375rem;
                            align-self: stretch;

                            .input-label {
                                color: var(--gray-700, #344054);
                                font-family: Inter;
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 1.25rem;
                            }

                            .user-first-name-input {
                                display: flex;
                                padding: 0.625rem 0.875rem;
                                align-items: center;
                                gap: 0.5rem;
                                align-self: stretch;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--base-white, #FFF);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            }

                            .user-last-name-input {
                                display: flex;
                                padding: 0.625rem 0.875rem;
                                align-items: center;
                                gap: 0.5rem;
                                align-self: stretch;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--base-white, #FFF);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            }

                            .user-first-name-input:focus-visible {
                                outline: none;
                            }

                            .user-last-name-input:focus-visible {
                                outline: none;
                            }
                        }
                    }
                }

                .input-field-view {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.375rem;
                    align-self: stretch;

                    .input-with-label {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.375rem;
                        align-self: stretch;

                        .input-label {
                            color: var(--gray-700, #344054);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.25rem;
                        }

                        .row-input-with-icon-view {
                            display: flex;
                            padding: 0.625rem 0.875rem;
                            align-items: center;
                            gap: 0.5rem;
                            align-self: stretch;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--base-white, #FFF);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                            .input-content {
                                display: flex;
                                align-items: center;
                                gap: 0.5rem;
                                flex: 1 0 0;

                                .input-with-icon {
                                    width: 100%;
                                    border: none;
                                }

                                .input-with-icon:focus-visible {
                                    outline: none;
                                }
                            }
                        }

                        .organization-name-input {
                            width: 100%;

                            .select__control {
                                display: flex;
                                padding: 0.313rem 0.875rem;
                                align-items: center;
                                gap: 0.5rem;
                                align-self: stretch;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--base-white, #FFF);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                                .select__value-container {
                                    padding: 0 !important;
                                }

                                .select__indicators {
                                    .select__indicator-separator {
                                        display: none;
                                    }

                                    .select__dropdown-indicator {
                                        color: #667085;
                                    }
                                }
                            }
                        }

                        .user-role-input {
                            width: 100%;

                            .select__control {
                                display: flex;
                                padding: 0.313rem 0.875rem;
                                align-items: center;
                                gap: 0.5rem;
                                align-self: stretch;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--base-white, #FFF);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                                .select__value-container {
                                    padding: 0 !important;
                                }

                                .select__indicators {
                                    .select__indicator-separator {
                                        display: none;
                                    }

                                    .select__dropdown-indicator {
                                        color: #667085;
                                    }
                                }
                            }
                        }
                    }
                }

                .add-user-modal-footer {
                    display: flex;
                    padding-top: 16px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    align-self: stretch;

                    .add-user-modal-footer-content {
                        display: flex;
                        padding: 0rem 1.5rem 1.5rem 0rem;
                        align-items: center;
                        gap: 0.75rem;
                        align-self: stretch;

                        .add-user-modal-footer-buttons {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            gap: 0.75rem;
                            flex: 1 0 0;

                            .cancel-user-button {
                                display: flex;
                                padding: 0.625rem 1.125rem;
                                justify-content: center;
                                align-items: center;
                                gap: 0.5rem;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--base-white, #FFF);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                                color: var(--gray-700, #344054);
                                font-family: Inter;
                                font-size: 1rem;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1.5rem;
                            }

                            .cancel-user-button:hover {
                                background-color: #f9f8f8;
                            }

                            .disable-add-user-button {
                                display: flex;
                                width: 6.1875rem;
                                padding: 0.625rem 1.125rem;
                                justify-content: center;
                                align-items: center;
                                gap: 0.5rem;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--gray-300, #D0D5DD);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                                color: var(--base-white, #FFF);
                                font-family: Inter;
                                font-size: 1rem;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1.5rem;    
                            }

                            .enable-add-user-button {
                                display: flex;
                                width: 6.1875rem;
                                padding: 0.625rem 1.125rem;
                                justify-content: center;
                                align-items: center;
                                gap: 0.5rem;
                                border-radius: 0.5rem;
                                border: 1px solid var(--primary-600, #000);
                                background: var(--primary-600, #000);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                                color: var(--base-white, #FFF);
                                font-family: Inter;
                                font-size: 1rem;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1.5rem;
                            }

                            .enable-add-user-button:hover {
                                background-color: #3c3a3a;
                            }
                        }

                        .error {
                            color: #E66464;
                            display: flex;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}