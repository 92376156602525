.daterangepicker {

    width: 53rem !important;
    height: 29.25rem !important;

    .ranges {
        display: flex;
        padding: 0.75rem 0.5rem  !important;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;
        align-self: stretch;
        border-right: 1px solid var(--gray-200, #EAECF0) !important;
        margin-top: 0rem !important;

        ul {
            li {
                display: flex;
                width: 9rem;
                padding: 0.7rem 1rem;
                align-items: center;
                border-radius: 0.375rem;
                background: var(--base-white, #FFF);

                color: var(--gray-700, #344054);
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.25rem;
            }

            .active {
                display: flex;
                width: 9rem;
                padding: 0.7rem 1rem;
                align-items: center;
                border-radius: 0.375rem;

                background: var(--primary-600, #000) !important;
                color: var(--base-white, var(--white, #FFF));
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.25rem;
            }
        }
    }
  
    .left {
        max-width: 20.5rem !important;
        width: 20.5rem !important;
        height: 25.2rem !important;
        border-left: none !important;
        padding-bottom: 0 !important;
        padding-left: 0rem !important;
        margin-right: 0.625rem !important;

        .calendar-table {
            padding: 0 !important;

            .table-condensed {
                width: 20.5rem !important;
                height: 24.5rem !important;
                padding: 1.25rem 1.5rem !important;

                thead {
                    tr {
                        .month {
                            color: var(--gray-700, #344054) !important;
                            text-align: center !important;
                            font-family: Inter !important;
                            font-size: 1rem !important;
                            font-style: normal !important;
                            font-weight: 600 !important;
                            line-height: 1.5rem !important;
                        }
                        
                        th {
                            color: var(--gray-700, #344054);
                            text-align: center;
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.25rem; 
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            border-radius: 1.25rem !important;
                            color: var(--gray-500, var(--Icon, #667085));
                            text-align: center;
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.25rem;
                        }

                        .active {
                            border-radius: 1.25rem !important;
                            background: var(--primary-600, #000) !important;
                            color: var(--base-white, var(--white, #FFF));
                            text-align: center;
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.25rem;
                        }

                        .in-range {
                            background: var(--gray-50, #F9FAFB);
                        }
                    }
                }
            }
        }
    }
  
    .right {
        max-width: 20.5rem !important;
        width: 20.5rem !important;
        height: 25.2rem !important;
        padding-left: 0.625rem !important;
        border-left: 1px solid var(--gray-200, #EAECF0) !important;


        .calendar-table {
            .table-condensed {
                width: 20.5rem !important;
                height: 24.5rem !important;
                padding: 1.25rem 1.5rem !important;
                border-right: 1px solid var(--gray-200, #EAECF0);

                thead {
                    tr {
                        .month {
                            color: var(--gray-700, #344054) !important;
                            text-align: center !important;
                            font-family: Inter !important;
                            font-size: 1rem !important;
                            font-style: normal !important;
                            font-weight: 600 !important;
                            line-height: 1.5rem !important;
                        }

                        th {
                            color: var(--gray-700, #344054);
                            text-align: center;

                            /* Text sm/Medium */
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.25rem; /* 142.857% */     
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            border-radius: 1.25rem !important;
                            color: var(--gray-500, var(--Icon, #667085));
                            text-align: center;
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.25rem;
                        }

                        .active {
                            border-radius: 1.25rem !important;
                            background: var(--primary-600, #000) !important;
                            color: var(--base-white, var(--white, #FFF));
                            text-align: center;
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.25rem;
                        }

                        .in-range {
                            background: var(--gray-50, #F9FAFB);
                        }
                    }
                }
            }
        }
    }

    .drp-buttons {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        background-color: #fff;

        .drp-selected {
            color: var(--gray-900, #101828);
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem;
            border-radius: 0.5rem;
            border: 1px solid var(--gray-300, #D0D5DD);
            background: var(--white, #FFF);
            padding: 0.625rem 0.875rem !important;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-left: auto !important;
        }

        .cancelBtn {
            margin-left: auto !important;
            padding: 0.625rem 1rem !important;
            border-radius: 0.5rem;
            border: 1px solid var(--gray-300, #D0D5DD);
            background: var(--base-white, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

            color: var(--gray-700, #344054);
            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem;
        }

        .cancelBtn:hover { 
            background-color: #f9f8f8;
        }

        .applyBtn {
            margin-left: 1rem !important;
            padding: 0.625rem 1rem !important;
            border-radius: 0.5rem;
            border: 1px solid var(--primary-600, #000);
            background: var(--primary-600, #000);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: var(--base-white, var(--white, #FFF));
            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem;
        }
    }
}

.single {
    width: auto !important;
    height: 18.1rem !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;


    .left {
        height: 18rem !important;

        .calendar-table {
            .table-condensed {
                height: 17rem !important;
            }
        }
    }

    .drp-buttons {
        border-top: none !important;
        margin: -0.1rem !important;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        background-color: #fff;
    }
}