.asset-details-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0.75rem;
    border: 1px solid var(--gray-200, #EAECF0);
    background: var(--white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

    .content {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        background: var(--white, #FFF);
        border-radius: 0.75rem;

        .column-1 {
            display: flex;
            width: 14.25rem;
            flex-direction: column;
            align-items: flex-start;
            border-top-left-radius: 0.75rem;
            border-bottom-left-radius: 0.75rem;

            .cell:nth-of-type(1) {
                border-top-left-radius: 0.75rem;
            }

            .cell:nth-of-type(10) {
                border-bottom-left-radius: 0.75rem;
            }

            .cell {
                display: flex;
                height: 3.5rem;
                padding: 1rem 1.5rem;
                align-items: center;
                align-self: stretch;
                border-bottom: 1px solid var(--gray-200, #EAECF0);
                background: #FAFAFB;
                color: var(--gray-600, #475467);
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.25rem;
            }
        }

        .column-2 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
            border-top-right-radius: 0.75rem;

            .cell:nth-of-type(1) {
                border-top-right-radius: 0.75rem;
            }

            .cell:nth-of-type(10) {
                border-bottom-right-radius: 0.75rem;
            }

            .cell {
                display: flex;
                height: 3.5rem;
                padding: 1rem 1.5rem;
                align-items: center;
                align-self: stretch;
                border-bottom: 1px solid var(--gray-200, #EAECF0);
                background: var(--white, #FFF);
                color: var(--gray-900, #101828);
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.25rem;
            }
        }
    }
}