main {
    .header-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        align-self: stretch;

        .portfolio-overview-page-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.625rem;
            align-self: stretch;


            .portfolio-overview-page-header {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.5rem;
                align-self: stretch;
            }

            .portfolio-overview-page-breadcrumbs {
                display: flex;
                align-items: center;

                .management {
                    color: #475467;
                    font-size: 0.875rem;
                    font-weight: 500;
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;
                }
                .portfolio-overview-page {
                    padding: 0.5rem;
                    background-color: #F2F2F2;
                    font-size: 0.875rem;
                    font-weight: 600;
                    border-radius: 0.5rem;
                    margin-left: 0.5rem;
                }
            }

            .portfolio-overview-header-content {
                display: flex;
                width: 100%;
                align-items: center;
                gap: 1rem;

                .manage-portfolio-overview-text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    align-self: stretch;
                    gap: 0.25rem;
                    flex: 1 0 0;
                    color: var(--gray-900, #101828);
                    font-family: Inter;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 2.375rem;
                }

                .select-filter {
                    .select__control {
                        display: flex;
                        width: 10.5625rem;
                        height: 2.75rem;
                        max-width: 12.5rem;
                        border-radius: 0.5rem;
                        border: 1px solid var(--gray-300, #D0D5DD);
                        background: var(--white, #FFF);
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                        padding-right: 7px;

                        .select__value-container {
                            margin-top: 0.5rem;
                            color: #101828;
                            font-weight: 500;
                            font-family: Inter;
                            .select__placeholder {
                                color: #101828;
                                font-family: Inter;
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 1.5rem;
                            }
                        }

                        .select__indicators {
                            .select__indicator-separator {
                                display: none;
                            }

                            .select__dropdown-indicator {
                                color: #667085;
                            }
                        }
                    }
                }

                .portfolio-filter-category {
                }

                .portfolio-filter-entity {
                }
            }
        }
    }

    .stat-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        align-self: stretch;

        .stat-section-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.5rem;
            align-self: stretch;

            .metric-group {
                display: flex;
                align-items: center;
                gap: 1.5rem;
                align-self: stretch;

                .metric-item {
                    display: flex;
                    padding: 1.5rem;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.75rem;
                    flex: 1 0 0;
                    border-radius: 0.75rem;
                    border: 1px solid var(--gray-200, #EAECF0);
                    background: var(--base-white, #FFF);
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
                    min-height: 8.75rem;

                    .header {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;

                        .title {
                            align-self: stretch;
                            color: var(--gray-900, #101828);
                            font-family: Inter;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.5rem;
                        }

                        .dropdown {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            .three-dots {
                                cursor: pointer;
                            }
                        }
                    }


                    .number-charts {
                        display: flex;
                        align-items: flex-end;
                        gap: 1rem;
                        align-self: stretch;

                        .number-badge {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 0.5rem;
                            flex: 1 0 0;

                            .stat-value {
                                align-self: stretch;
                                color: var(--gray-900, #101828);
                                font-family: Inter;
                                font-size: 1.5rem;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1.75rem;
                                letter-spacing: -0.03rem;
                            }

                            .change-stat-value {
                                display: flex;
                                align-items: center;
                                gap: 0.5rem;
                                align-self: stretch;

                                .change-stat {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 0.125rem;

                                    .increase-stat-text {
                                        color: var(--success-700, #027A48);
                                        text-align: center;
                                        font-family: Inter;
                                        font-size: 0.875rem;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 1.25rem;
                                    }

                                    .decrease-stat-text {
                                        color: var(--error-700, #B42318);
                                        text-align: center;
                                        font-family: Inter;
                                        font-size: 0.875rem;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 1.25rem;
                                    }
                                }

                                .stat-value {
                                    flex: 1 0 0;
                                    color: var(--gray-600, #475467);
                                    font-family: Inter;
                                    font-size: 0.875rem;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 1.25rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .graph-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        align-self: stretch;

        .graph-section-container {
            display: flex;
            align-items: flex-start;
            gap: 1.5rem;
            align-self: stretch;

            .graph-card {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex: 1 0 0;
                align-self: stretch;
                border-radius: 0.75rem;
                border: 1px solid var(--gray-200, #EAECF0);
                background: var(--white, #FFF);
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

                .content {
                    display: flex;
                    padding: 1.5rem;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 1.5rem;
                    flex: 1 0 0;
                    align-self: stretch;

                    .content-header {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 1.25rem;
                        align-self: stretch;

                        .content {
                            display: flex;
                            align-items: flex-start;
                            gap: 1rem;
                            align-self: stretch;
                            flex-direction: row;

                            .select-filter {
                                .select__control {
                                    display: flex;
                                    width: 10.5625rem;
                                    height: 2.75rem;
                                    max-width: 12.5rem;
                                    border-radius: 0.5rem;
                                    border: 1px solid var(--gray-300, #D0D5DD);
                                    background: var(--white, #FFF);
                                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            
                                    .select__value-container {
                                        .select__placeholder {
                                            color: #101828;
                                            font-family: Inter;
                                            font-size: 0.875rem;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 1.5rem;
                                        }
                                    }
            
                                    .select__indicators {
                                        .select__indicator-separator {
                                            display: none;
                                        }
            
                                        .select__dropdown-indicator {
                                            color: #667085;
                                        }
                                    }
                                }
                            }

                            .text-view {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: flex-start;
                                gap: 0.25rem;
                                flex: 1 0 0;
                                align-self: stretch;

                                .text {
                                    align-self: stretch;
                                    color: var(--gray-900, #101828);
                                    font-family: Inter;
                                    font-size: 1rem;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 1.5rem;
                                }
                            }

                            .dropdown {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
    
                                .three-dots {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                .investment-assets-value-by-category-view {
                    width: 100%;

                    .apexcharts-canvas {
                        .apexcharts-svg {
                            .apexcharts-graphical {
                                .apexcharts-bar-series {
                                    .apexcharts-datalabels {
                                        .apexcharts-text {
                                            color: #96A5B9 !important;
                                            fill: #96A5B9 !important;
                                            font-family: Inter !important;
                                            font-size: 0.625rem !important;
                                            font-style: normal !important;
                                            font-weight: 500 !important;
                                            line-height: 1.25rem !important;
                                        }
                                    }
                                }
                            }

                            .apexcharts-yaxis {
                                .apexcharts-yaxis-title {
                                    .apexcharts-yaxis-title-text {
                                        color: var(--gray-600, #475467) !important;
                                        fill: var(--gray-600, #475467) !important;
                                        text-align: center !important;
                                        font-family: Inter !important;
                                        font-size: 0.75rem !important;
                                        font-style: normal !important;
                                        font-weight: 500 !important;
                                        line-height: 1.125rem !important;
                                    }
                                }

                                .apexcharts-yaxis-texts-g {
                                    .apexcharts-yaxis-label {
                                        color: var(--gray-700, #344054) !important;
                                        text-align: right !important;
                                        font-family: Inter !important;
                                        font-size: 0.75rem !important;
                                        font-style: normal !important;
                                        font-weight: 400 !important;
                                        line-height: 1.125rem !important;
                                    }
                                }
                            }

                            .apexcharts-xaxis {
                                .apexcharts-xaxis-title {
                                    .apexcharts-xaxis-title-text {
                                        color: var(--gray-600, #475467) !important;
                                        fill: var(--gray-600, #475467) !important;
                                        text-align: center !important;
                                        font-family: Inter !important;
                                        font-size: 0.75rem !important;
                                        font-style: normal !important;
                                        font-weight: 500 !important;
                                        line-height: 1.125rem !important;
                                    }
                                }
                                .apexcharts-xaxis-texts-g {
                                    .apexcharts-xaxis-label {
                                        color: var(--gray-600, #475467) !important;
                                        text-align: center !important;
                                        font-family: Inter !important;
                                        font-size: 0.75rem !important;
                                        font-style: normal !important;
                                        font-weight: 400 !important;
                                        line-height: 1.125rem !important;
                                    }
                                }
                            }

                            .apexcharts-legend {
                                display: flex;
                                align-items: flex-start;
                                gap: 0.8125rem;

                                .apexcharts-legend-series {
                                    .apexcharts-legend-marker {
                                        border-radius: 0.75rem !important;
                                    }

                                    .apexcharts-legend-text {
                                        color: var(--gray-600, #475467) !important;
                                        font-family: Inter !important;
                                        font-size: 0.875rem !important;
                                        font-style: normal !important;
                                        font-weight: 400 !important;
                                        line-height: 1.25rem !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ytd-irr-view-gain-loss-menu {
    .MuiPaper-root {
        top: 12.1875rem !important;
        width: 19rem !important;
        border-radius: 0.5rem !important;

        .MuiList-root {
            .MuiMenuItem-root {
                border-radius: 0.5rem;
                background-color: transparent;
                color: var(--gray-900, #101828);
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
            }

            .MuiMenuItem-root:hover {
                background-color: rgb(240, 240, 240);
            }

            .Mui-selected {
                color: #FFFFFF;
                background-color: #000000;
            }
            .Mui-selected:hover {
                color: #FFFFFF;
                background-color: #000000;
            }
        }

        .MuiList-padding {
            padding: 0.6rem;
        }
    }
}

.all-time-view-gain-loss-menu {
    .MuiPaper-root {
        top: 12.1875rem !important;
        width: 19rem !important;
        border-radius: 0.5rem !important;

        .MuiList-root {
            .MuiMenuItem-root {
                border-radius: 0.5rem;
                background-color: transparent;
                color: var(--gray-900, #101828);
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
            }

            .MuiMenuItem-root:hover {
                background-color: rgb(240, 240, 240);
            }

            .Mui-selected {
                color: #FFFFFF;
                background-color: #000000;
            }
            .Mui-selected:hover {
                color: #FFFFFF;
                background-color: #000000;
            }
        }

        .MuiList-padding {
            padding: 0.6rem;
        }
    }
}

.view-bar-line-chart-investment-value-by-category-graph {
    .MuiPaper-root {
        width: 19rem !important;
        border-radius: 0.5rem !important;

        .MuiList-root {
            .MuiMenuItem-root {
                border-radius: 0.5rem;
                background-color: transparent;
                color: var(--gray-900, #101828);
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
            }

            .MuiMenuItem-root:hover {
                background-color: rgb(240, 240, 240);
            }

            .Mui-selected {
                color: #FFFFFF;
                background-color: #000000;
            }
            .Mui-selected:hover {
                color: #FFFFFF;
                background-color: #000000;
            }
        }

        .MuiList-padding {
            padding: 0.6rem;
        }
    }
}
.Category, .Entity, .Date {
  top: 2px; /* Adjust the top position when the label is floating */
  font-size: 12px; /* Adjust the font size when the label is floating */
  left: 11px;
  //color: var(--gray-600, #475467);
}

@media (max-width: 1600px) {
  .graph-section-container {
    flex-direction: column;
  }
}