// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  

  #root {
    .MuiBox-root.css-utqemq{
      height: 100vh;

      .MuiBox-root.css-7isv79 {
        height: 100vh;
      }

      main {
        background-color: #F9FAFB;
        overflow-y: auto;
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// login page scss
@import '_login';

// reset password page scss
@import '_resetPassword';

// forgot password page scss
@import '_forgotPassword';

// sidebar scss
@import '_sidebar';

// organization scss
@import '_organizationPage';

// add organization modal scss
@import '_addOrganizationModal.scss';

// edit organization modal scss
@import '_editOrganizationModal.scss';

// user scss
@import '_userPage';

// add user modal scss
@import '_addUserModal.scss';

// edit user modal scss
@import '_editUserModal.scss';

// alert confirmationmodal scss
@import '_alertConfirmationModal.scss';

// account setting scss
@import '_accountSetting.scss';

// alert modal scss
@import '_alertModal.scss';

// asset summary scss
@import '_assetSummaryPage.scss';

// add asset modal scss
@import '_addAssetModal.scss';

// add asset summary detail scss
@import '_assetSummaryDetailPage.scss';

// add dateRangePicker scss
@import '_dateRangePicker.scss';

// add assetDetails scss
@import '_assetDetails.scss';

// add capital Asset Summary scss
@import '_capitalAssetSummary.scss';

// add events Asset Summary scss
@import '_eventsSummary.scss';

// add add events Asset Summary scss
@import '_assetSummaryAddEventModal.scss';

// add add events database page scss
@import '_eventDataBasePage.scss';


// add add portfolio OverView page scss
@import '_portfolioOverView.scss';