main {
    .header-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        align-self: stretch;

        .event-database-page-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.625rem;
            align-self: stretch;

            .event-database-page-header {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.5rem;
                align-self: stretch;
            }

            .event-database-breadcrumbs {
                display: flex;
                align-items: center;

                .database {
                    color: #475467;
                    font-size: 0.875rem;
                    font-weight: 500;
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;
                }
                .event-database {
                    padding: 0.5rem;
                    background-color: #F2F2F2;
                    font-size: 0.875rem;
                    font-weight: 600;
                    border-radius: 0.5rem;
                    margin-left: 0.5rem;
                }
            }

            .event-database-header-content {
                display: flex;
                width: 100%;
                align-items: center;
                gap: 1rem;

                .select-date-button {
                    display: flex;
                    padding: 0.625rem 1rem;
                    justify-content: center;
                    align-items: center;
                    gap: 0.5rem;
                    border-radius: 0.5rem;
                    border: 1px solid var(--gray-300, #D0D5DD);
                    background: var(--base-white, #FFF);
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    color: var(--gray-500, var(--Icon, #667085));
                    font-family: Inter;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.25rem;
                }

                .manage-event-database-text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    align-self: stretch;
                    gap: 0.25rem;
                    flex: 1 0 0;
                    color: var(--gray-900, #101828);
                    font-family: Inter;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 2.375rem; /* 158.333% */
                }

                .event-database-table-filters {

                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 0.75rem;

                    .filters-button {
                        display: flex;
                        padding: 0.625rem 1rem;
                        justify-content: center;
                        align-items: center;
                        gap: 0.5rem;
                        border: 1px solid #D0D5DD;
                        border-radius: 0.5rem;
                        background-color: white;
                        color: #344054;
                        font-size: 0.875rem;
                        font-weight: 600;
                        width: 6.4375rem;
                    }
            
                    .add-event-database-button {
                        display: flex;
                        height: 2.375rem;
                        padding: 0.625rem 1rem;
                        justify-content: center;
                        align-items: center;
                        gap: 0.5rem;
                        border-radius: 0.5rem;
                        border: 1px solid #000;
                        background: var(--primary-600, #000);
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                        color: var(--base-white, #FFF);
                        font-family: Inter;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.25rem;
                    }
            
                    .add-event-database-button:hover {
                        background-color: #3c3a3a;
                    }
                }

                .add-event-button {
                    display: flex;
                    height: 2.375rem;
                    padding: 0.625rem 1rem;
                    justify-content: center;
                    align-items: center;
                    gap: 0.5rem;
                    border-radius: 0.5rem;
                    border: 1px solid #000;
                    background: var(--primary-600, #000);
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    color: var(--base-white, var(--white, #FFF));
                    font-family: Inter;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.25rem;
                }

                .add-event-button:hover {
                    background-color: #3c3a3a;
                }
            }
        }
    }

    .event-database-table {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        align-self: stretch;
        //max-height: 55em !important;
    
        border-radius: 0.75rem !important;
        border: 1px solid var(--gray-200, #EAECF0);
        background: var(--base-white, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10) !important;
    
        table {
            background: var(--base-white, #FFF);
    
            thead {
                border-bottom: 1px solid #EAECF0;
    
                tr {
    
                    th {
                        font-family: Inter;
                        font-size: 0.75rem;
                        font-weight: 500;
                        color: #475467;
                    }

                    .share-price-column {
                        display: flex;
                        gap: 0.3rem;
                    }
                }
            }
        }
    
        table {
    
            tbody {
    
                tr {
    
                    td {
                        height: 4.5rem;
                        padding: 1rem 1.5rem;
                        font-family: Inter;
                        font-size: 0.875rem;
                        font-weight: 400;
                        color: #475467;
    
                        .disabled-file {
                            opacity: 0.3;
                        }

                        .file-icon {
                            cursor: pointer;
                        }
    
                        .trash-icon {
                            cursor: pointer;
                        }
    
                        .edit-icon {
                            cursor: pointer;
                        }
                    }
    
                    td:nth-of-type(1) {
                        color: var(--gray-900, #101828);
                        font-family: Inter;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.25rem;
                    }
                }
            }
        }
    }
}