.alert-modal-view {
    display: flex;
    justify-content: center;
    align-items: center;

    .alert-modal {
        display: flex;
        width: 40rem;
        flex-direction: column;
        align-items: center;
        border-radius: 0.75rem;
        background: var(--base-white, #FFF);
        box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);

        .alert-modal-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-self: stretch;

            .alert-modal-header-content {
                display: flex;
                padding: 1.5rem 1.5rem 1.25rem 1.5rem;
                border-radius: 0.75rem 0.75rem 0rem 0rem;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
                background: var(--base-white, #FFF);

                .alert-modal-header-icons-view {
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;

                    .alert-modal-header-feature-icon {
                        display: flex;
                        width: 3rem;
                        height: 3rem;
                        padding: 0.75rem;
                        justify-content: center;
                        align-items: center;
                        border-radius: 0.625rem;
                        border: 1px solid var(--gray-200, #EAECF0);
                        background: var(--base-white, #FFF);
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    
                        .header-building-icon {
                            width: 1.5rem;
                            height: 1.5rem;
                            flex-shrink: 0;
                        }
                    }

                    .alert-modal-header-close-button {
                        display: flex;
                        padding: 0.625rem;
                        justify-content: center;
                        align-items: center;
                        right: 1rem;
                        top: 1rem;
                        border-radius: 0.5rem;

                        .close-modal-icon {
                            cursor: pointer;
                            width: 1.5rem;
                            height: 1.5rem;
                        }
                    }

                }

                .alert-modal-header-text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.25rem;
                    align-self: stretch;

                    .alert-modal-header-title-text {
                        align-self: stretch;
                        color: var(--gray-900, #101828);
                        font-family: Inter;
                        font-size: 1.125rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.75rem;
                    }

                    .alert-modal-header-info-text {
                        align-self: stretch;
                        color: var(--gray-600, #475467);
                        font-family: Inter;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1.25rem;
                    }
                }
            }
        }

        .alert-modal-content {
            display: flex;
            padding: 0rem 1.5rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.25rem;
            align-self: stretch;

            .alert-form {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1rem;
                align-self: stretch;

                .alert-modal-footer {
                    display: flex;
                    padding-top: 16px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    align-self: stretch;

                    .alert-modal-footer-content {
                        display: flex;
                        padding: 0rem 1.5rem 1.5rem 1.5rem;
                        align-items: center;
                        gap: 0.75rem;
                        align-self: stretch;

                        .alert-modal-footer-buttons {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            gap: 0.75rem;
                            flex: 1 0 0;

                            .cancel-user-button {
                                display: flex;
                                padding: 0.625rem 1.125rem;
                                justify-content: center;
                                align-items: center;
                                gap: 0.5rem;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--base-white, #FFF);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                                color: var(--gray-700, #344054);
                                font-family: Inter;
                                font-size: 1rem;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1.5rem;
                            }

                            .cancel-user-button:hover {
                                background-color: #f9f8f8;
                            }

                            .disable-alert-button {
                                display: flex;
                                width: 6.1875rem;
                                padding: 0.625rem 1.125rem;
                                justify-content: center;
                                align-items: center;
                                gap: 0.5rem;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--gray-300, #D0D5DD);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                                color: var(--base-white, #FFF);
                                font-family: Inter;
                                font-size: 1rem;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1.5rem;    
                            }

                            .enable-alert-button {
                                display: flex;
                                width: 6.1875rem;
                                padding: 0.625rem 1.125rem;
                                justify-content: center;
                                align-items: center;
                                gap: 0.5rem;
                                border-radius: 0.5rem;
                                border: 1px solid var(--primary-600, #000);
                                background: var(--primary-600, #000);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                                color: var(--base-white, #FFF);
                                font-family: Inter;
                                font-size: 1rem;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1.5rem;
                            }

                            .enable-alert-button:hover {
                                background-color: #3c3a3a;
                            }
                        }

                        .error {
                            color: #E66464;
                            display: flex;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    .alert-modal:focus-visible {
        outline: none;
    }
}