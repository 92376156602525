main {
  .header-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;

    .asset-summary-page-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.625rem;
      align-self: stretch;

      .asset-summary-page-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        align-self: stretch;
      }

      .asset-summary-breadcrumbs {
        display: flex;
        align-items: center;

        .management {
          color: #475467;
          font-size: 0.875rem;
          font-weight: 500;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }

        .asset-summary {
          padding: 0.5rem;
          background-color: #F2F2F2;
          font-size: 0.875rem;
          font-weight: 600;
          border-radius: 0.5rem;
          margin-left: 0.5rem;
        }
      }

      .asset-summary-header-content {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 1rem;

        .manage-asset-summary-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;
          gap: 0.25rem;
          flex: 1 0 0;
          color: var(--gray-900, #101828);
          font-family: Inter;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 600;
          line-height: 2.375rem; /* 158.333% */
        }

        .floating-selector-view {
          min-width: 10rem;


          .select-filter {
            .select__control {
              display: flex;
              width: 10.5625rem;
              height: 2.75rem;
              max-width: 12.5rem;
              border-radius: 0.5rem;
              border: 1px solid var(--gray-300, #D0D5DD);
              background: var(--white, #FFF);
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              padding-right: 7px;

              .select__value-container {
                margin-top: 0.5rem;
                color: #101828;
                font-weight: 500;
                font-family: Inter;

                .select__placeholder {
                  color: #101828;
                  font-family: Inter;
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.5rem;
                }
              }

              .select__indicators {
                .select__indicator-separator {
                  display: none;
                }

                .select__dropdown-indicator {
                  color: #667085;
                }
              }
            }
          }

          //.portfolio-filter-category {
          //  width: 100%;
          //
          //  .menu-container {
          //    display: flex;
          //    padding-top: 0.75rem;
          //    padding-right: 1.2rem;
          //    padding-bottom: 0.75rem;
          //    padding-left: 1.2rem;
          //    flex-direction: column;
          //    gap: 0.5rem;
          //    align-self: stretch;
          //
          //    .select__option {
          //      border-radius: 0.375rem;
          //      padding-left: 1rem;
          //      padding-right: 1rem;
          //    }
          //  }
          //
          //  .select__control {
          //    min-width: 10rem !important;
          //    display: flex;
          //    align-items: center;
          //    gap: 0.5rem;
          //    align-self: stretch;
          //    border-radius: 0.5rem;
          //    border: 1px solid var(--gray-300, #D0D5DD);
          //    background: var(--white, #FFF);
          //    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          //    box-shadow: none;
          //    padding: 0.1rem 0.7rem 0.0375rem 0.5rem;
          //
          //    .select__value-container {
          //      padding: 0 !important;
          //      margin-top: 0.5rem;
          //      color: #101828;
          //      font-weight: 500;
          //      font-family: Inter;
          //    }
          //
          //    .select__indicators {
          //
          //      .select__indicator-separator {
          //        display: none;
          //      }
          //
          //      .select__dropdown-indicator {
          //        color: #667085;
          //      }
          //
          //      .select__indicator {
          //        color: #000000;
          //      }
          //    }
          //
          //    .select__value-container {
          //      flex: 0 0 calc(60% - 0.25rem);
          //
          //      .select__single-value {
          //        color: #101828;
          //        font-family: Inter;
          //        font-size: 0.875rem;
          //        font-style: normal;
          //        font-weight: 500;
          //        line-height: 1.125rem;
          //      }
          //    }
          //  }
          //
          //  .person-icon {
          //    margin-left: 1rem;
          //    stroke: #667085;
          //  }
          //
          //  .selected-person-icon {
          //    display: flex;
          //    width: 1.5rem;
          //    height: 1.5rem;
          //    padding: 0.25rem;
          //    justify-content: center;
          //    align-items: center;
          //    border-radius: 12.5rem;
          //    background: var(--gray-100, #F2F4F7);
          //  }
          //
          //  .select__menu {
          //    padding: 1rem;
          //
          //    .select__option {
          //      border-radius: 0.5rem;
          //    }
          //  }
          //}
        }

        .asset-summary-table-filters {

          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 0.75rem;

          .filters-button {
            display: flex;
            padding: 0.625rem 1rem;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            border: 1px solid #D0D5DD;
            border-radius: 0.5rem;
            background-color: white;
            color: #344054;
            font-size: 0.875rem;
            font-weight: 600;
            width: 6.4375rem;
          }

          .add-asset-summary-button {
            display: flex;
            height: 2.375rem;
            padding: 0.625rem 1rem;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            border-radius: 0.5rem;
            border: 1px solid #000;
            background: var(--primary-600, #000);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: var(--base-white, #FFF);
            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem;
          }

          .add-asset-summary-button:hover {
            background-color: #3c3a3a;
          }
        }
      }
    }
  }

  .table-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;

    border-radius: 0.75rem;
    border: 1px solid var(--gray-200, #EAECF0);
    background: var(--base-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

    table {
      background: var(--base-white, #FFF);

      thead {
        border-bottom: 1px solid #EAECF0;

        tr {

          th {
            font-family: Inter;
            font-size: 0.75rem;
            font-weight: 500;
            color: #475467;
          }

          th:nth-of-type(1) {
            padding-left: 1.5rem;
          }

          th:nth-of-type(4) {
            padding-right: 5rem;
          }
        }
      }
    }

    table {

      tbody {

        tr {

          th {
            font-family: Inter;
            font-size: 0.875rem;
            font-weight: 600;
            color: #101828;
            padding-left: 1.5rem
          }

          td {
            font-family: Inter;
            font-size: 0.875rem;
            font-weight: 400;
            color: #475467;

            .trash-icon {
              cursor: pointer;
            }

            .edit-icon {
              cursor: pointer;
            }

            .export-icon {
              cursor: pointer;
            }
          }

          td:nth-of-type(1) {
            color: var(--gray-900, #101828);
            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem;
          }

          .pending-status {
            padding: 1rem 1.5rem;

            span {
              display: flex;
              padding: 0.125rem 0.5rem;
              align-items: center;
              border-radius: 1rem;
              background: var(--warning-50, #FFFAEB);
              mix-blend-mode: multiply;
              color: var(--warning-700, #B54708);
              text-align: center;
              font-family: Inter;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 500;
              line-height: 1.125rem;
              width: fit-content;
            }
          }

          .active-status {
            padding: 1rem 1.5rem;

            span {
              display: flex;
              padding: 0.125rem 0.5rem;
              align-items: center;
              border-radius: 1rem;
              background: var(--success-50, #ECFDF3);
              mix-blend-mode: multiply;
              color: var(--success-700, #027A48);
              text-align: center;
              font-family: Inter;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 500;
              line-height: 1.125rem;
              width: fit-content;
            }
          }

          td:nth-of-type(2) {
            color: var(--gray-600, #475467);
            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
          }


          .status {
            display: flex !important;
            align-items: baseline !important;
            border-bottom: none;
          }

          .status-selector {
            // position: absolute;
            width: 5.125rem;
            height: 1.375rem;

            .select__control {
              display: flex;
              align-items: center;
              padding-left: 0.4rem;
              padding-right: 0.4rem;
              box-shadow: none;
              gap: 0.25rem;
              border-radius: 1rem;
              background: var(--primary-100, #F6F8FC);
              mix-blend-mode: multiply;
              border: none;
              text-align: center;
              font-family: Inter;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 500;
              line-height: 1.125rem;
              height: 1.375rem;
              min-height: 1.375rem;
              cursor: pointer;

              .select__value-container {
                position: initial;
                padding: 0 !important;
                height: 1.375rem;
              }

              .select__indicators {
                display: none;
              }

            }
          }

          .last-status-selector {
            .select__menu {
              top: 20%;
              right: 100%;
              transform: translate(0, -50%);
            }
          }

          .positive {
            .select__control {
              .select__value-container {
                .select__single-value {
                  flex: 1 0 0;
                  color: var(--success-700, #027A48);
                  font-family: Inter;
                  font-size: 0.75rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.125rem;
                }
              }
            }
          }

          .negative {
            .select__control {
              .select__value-container {
                .select__single-value {
                  flex: 1 0 0;
                  color: #B42318;
                  font-family: Inter;
                  font-size: 0.75rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.125rem;
                }
              }
            }
          }

          .neutral {
            .select__control {
              .select__value-container {
                .select__single-value {
                  flex: 1 0 0;
                  color: #C09000;
                  font-family: Inter;
                  font-size: 0.75rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.125rem;
                }
              }
            }
          }
        }

        .table-data-row {
          td:not(:nth-of-type(9)):not(:nth-of-type(10)):hover {
            background: var(--gray-50, #F9FAFB);
            cursor: pointer;
          }
        }

        .total-row {
          background: var(--gray-50, #F9FAFB);
        }
      }
    }
  }
}