.add-asset-modal-view {
    display: flex;
    justify-content: center;
    align-items: center;
    //max-height: 99vh;
    max-height: 892px;

    .add-asset-modal {
        display: flex;
        width: 46.5625rem;
        flex-direction: column;
        align-items: center;
        //border-radius: 0.75rem;
        border-radius: 0.75rem 0.5rem 0.5rem 0.75rem;
        background: var(--white, #FFF);
        box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);

        //height: 100%;
        overflow-y: auto;
        position: relative;
        //scrollbar-width: thin; /* Thinner scrollbar */
        //scrollbar-color: #888 #f1f1f1; /* Thumb color, Track color */

        .add-asset-modal-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-self: stretch;
            background: var(--base-white, #FFF);
            border-radius: 0.75rem;

            .add-asset-modal-header-content {
                display: flex;
                padding: 1rem 1.5rem 0rem 1.5rem;
                border-radius: 0.75rem 0.75rem 0rem 0rem;
                flex-direction: column;
                align-items: flex-start;
                gap: 1rem;
                align-self: stretch;
                background: var(--white, #FFF);

                .add-asset-modal-header-icons-view {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    .add-asset-modal-header-feature-icon {
                        display: flex;
                        width: 3rem;
                        height: 3rem;
                        padding: 0.75rem;
                        justify-content: center;
                        align-items: center;
                        border-radius: 0.625rem;
                        border: 1px solid var(--gray-200, #EAECF0);
                        background: var(--base-white, #FFF);
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    }

                    .add-asset-modal-header-close-button {
                        display: flex;
                        padding: 0.625rem;
                        justify-content: center;
                        align-items: center;
                        right: 1rem;
                        top: 1rem;
                        border-radius: 0.5rem;

                        .close-modal-icon {
                            cursor: pointer;
                            width: 1.5rem;
                            height: 1.5rem;
                        }
                    }
                }

                .add-asset-modal-header-text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.25rem;
                    align-self: stretch;
                    
                    .add-asset-modal-header-title-text {
                        align-self: stretch;
                        color: var(--gray-900, #101828);
                        font-family: Inter;
                        font-size: 1.125rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.75rem;
                    }
                }
            }
        }

        .add-asset-modal-content {
            display: flex;
            padding: 0rem 1.5rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            align-self: stretch;
            height: 100%;

            .date-picker-container {
                width: 100%;

                .MuiFormControl-root {
                    width: 100%;

                    .MuiInputBase-root {
                        max-height: 40px;
                        justify-content: space-between;
                    }
                }
            }

            .progress-step {
                display: flex;
                padding: 1.75rem 0rem;
                flex-direction: column;
                align-items: center;
                align-self: stretch;
                border-radius: 0.75rem;
                border: 1px solid #EAECF0;
                background: var(--white, #FFF);

                .progress-step-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 0.5rem;
                    align-self: stretch;

                    .line-view {
                        display: flex;
                        height: 0.125rem;
                        padding-left: 0px;
                        justify-content: center;
                        align-items: center;
                        margin-right: 0.6rem;
                        margin-left: 0.6rem;
                        margin-top: -3.3125rem;

                        .line {
                            position: absolute;
                            width: 19.8125rem;
                            height: 0.125rem;
                            background: var(--gray-200, #EAECF0);
                        }

                        .active-line {
                            position: absolute;
                            width: 19.8125rem;
                            height: 0.125rem;
                            background: var(--black, #000);
                        }
                    }

                    .asset-details {
                        display: flex;
                        width: 19.12206rem;
                        flex-direction: column;
                        align-items: center;
                        gap: 0.75rem;

                        .icon-view {
                            display: flex;
                            width: 1.5rem;
                            height: 1.5rem;
                            justify-content: center;
                            align-items: center;
                            border-radius: 0.75rem;
                            background: var(--primary-50, #F9F5FF);

                            .active-progress-icon {
                                width: 1.5rem;
                                height: 1.5rem;
                                flex-shrink: 0;
                                fill: var(--primary-600, #000);
                            }

                            .progress-completed-icon {
                                border-radius: 0.75rem;
                                background: var(--primary-600, #000);
                            }
                        }

                        .content {
                            display: flex;
                            padding-top: 2px;
                            flex-direction: column;
                            align-items: center;
                            align-self: stretch;

                            .content-title-text {
                                align-self: stretch;
                                color: var(--primary-700, var(--black, #000));
                                text-align: center;
                                font-family: Inter;
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1.25rem;
                            }

                            .content-info-text {
                                align-self: stretch;
                                color: var(--primary-600, var(--black, #000));
                                text-align: center;
                                font-family: Inter;
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 1.25rem;
                            }
                        }
                    }

                    .capital-invested {
                        display: flex;
                        width: 19.12206rem;
                        flex-direction: column;
                        align-items: center;
                        gap: 0.75rem;

                        .icon-view {
                            display: flex;
                            width: 1.5rem;
                            height: 1.5rem;
                            justify-content: center;
                            align-items: center;
                            border-radius: 0.75rem;
                            background: var(--gray-100, #F2F4F7);

                            .inactive-progress-icon {
                                width: 1.5rem;
                                height: 1.5rem;
                                flex-shrink: 0;
                            }
                        }

                        .content {
                            display: flex;
                            padding-top: 2px;
                            flex-direction: column;
                            align-items: center;
                            align-self: stretch;

                            .content-title-text {
                                align-self: stretch;
                                color: var(--dark-gray, #78899B);
                                text-align: center;
                                font-family: Inter;
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1.25rem;
                            }

                            .content-info-text {
                                align-self: stretch;
                                color: var(--dark-gray, #78899B);
                                text-align: center;                                
                                font-family: Inter;
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 1.25rem;
                            }
                        }
                    }

                    .capital-invested-active {

                        .icon-view {
                            background: var(--primary-600, #000);
                        }

                        .content {

                            .content-title-text {
                                color: var(--primary-700, var(--black, #000));
                            }

                            .content-info-text {
                                color: var(--primary-600, var(--black, #000));
                            }
                        }
                    }

                }
            }

            .add-asset-form {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.5rem;
                align-self: stretch;

                .input-field-view {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.375rem;
                    align-self: stretch;

                    .input-with-label {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.375rem;
                        align-self: stretch;

                        .input-label {
                            color: var(--gray-700, #344054);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.25rem;
                        }

                        .asset-name-input {
                            width: 100%;
                            display: flex;
                            padding: 0.625rem 0.875rem;
                            align-items: center;
                            gap: 0.5rem;
                            align-self: stretch;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--white, #FFF);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                        }

                        .asset-name-input:focus-visible {
                            outline: none;
                        }


                        .row-input-with-icon-view {
                            display: flex;
                            padding-top: 0.19rem;
                            padding-bottom: 0.19rem;
                            align-items: center;
                            gap: 0.5rem;
                            align-self: stretch;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--base-white, #FFF);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                            .input-content {
                                display: flex;
                                align-items: center;
                                gap: 0.5rem;
                                flex: 1 0 0;

                                .sponsor-input {
                                    .custom-menu {
                                        display: flex;
                                        //justify-content: space-between;
                                        justify-content: flex-end;
                                        padding: 0.25rem 0rem;
                                        gap: 0.5rem;

                                        .add-entity-view {
                                            display: flex;
                                            padding: 0.625rem 0.75rem;
                                            justify-content: center;
                                            align-items: center;
                                            gap: 0.5rem;
                                            align-self: stretch;
                                            border-radius: 0.5rem;
                                            border: 1px solid #D0D5DD;
                                            background: var(--base-white, #FFF);
                                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                                            cursor: pointer;
                                        }

                                        .add-entity-view:hover {
                                            background-color: #f9f8f8;
                                        }

                                        .searchable-input-view {
                                            display: flex;
                                            padding: 0.5625rem 0.875rem;
                                            align-items: center;
                                            gap: 0.5rem;
                                            align-self: stretch;
                                            border-radius: 6.25rem;
                                            background: var(--search, #F1F1F1);
                                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                                            
                                            .searchable-input {
                                                border: none;
                                                width: 100%;
                                                background: var(--search, #F1F1F1);
                                            }

                                            .searchable-input:focus-visible {
                                                outline: none;
                                            }
                                        }
                                    }

                                    .person-icon {
                                        margin-left: 1rem;
                                        stroke: #667085;
                                    }

                                    .selected-person-icon {
                                        display: flex;
                                        width: 1.5rem;
                                        height: 1.5rem;
                                        padding: 0.25rem;
                                        justify-content: center;
                                        align-items: center;
                                        border-radius: 12.5rem;
                                        background: var(--gray-100, #F2F4F7);
                                    }
                                }

                                .sponsor-input {
                                    width: 100%;
        
                                    .select__control {
                                        display: flex;
                                        align-items: center;
                                        gap: 0.5rem;
                                        align-self: stretch;
                                        border-radius: 0.5rem;
                                        border: none;
                                        box-shadow: none;
                                        background: var(--base-white, #FFF);

                                        .select__value-container {
                                            padding: 0 !important;
                                        }
        
                                        .select__indicators {
                                            .select__indicator-separator {
                                                display: none;
                                            }
        
                                            .select__dropdown-indicator {
                                                color: #667085;
                                            }
                                        }
                                    }

                                    .select__menu-portal {
                                        position: initial !important;
                                    }
                                }

                                .sponsor-input:focus-visible {
                                    outline: none;
                                }

                                .input-with-icon {
                                    width: 100%;
                                    border: none;
                                }

                                .input-with-icon:focus-visible {
                                    outline: none;
                                }
                            }

                            .commitment-input {
                                padding: 0.374rem;

                                .clear-button {
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .MuiFormControl-root {
                        .MuiFormGroup-root {
                            .MuiFormControlLabel-root {
                                .MuiFormControlLabel-label {
                                    color: var(--gray-700, #344054);
                                    font-family: Inter;
                                    font-size: 0.875rem;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 1.25rem;
                                }
                            }
                        }
                    }
                }
                .input-row {
                    display: flex;
                    align-items: flex-start;
                    gap: 1rem;
                    align-self: stretch;


                    .input-field-view {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.5rem;
                        flex: 1 0 0;
                        align-self: stretch;
    
                        .input-with-label {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 0.375rem;
                            align-self: stretch;
    
                            .input-label {
                                color: var(--gray-700, #344054);
                                font-family: Inter;
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 1.25rem;
                            }
    
                            .selector-input {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                gap: 0.5rem;
                                align-self: stretch;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--white, #FFF);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                                padding-right: 7px;

                                .select__control {
                                    display: flex;
                                    width: 100%;
                                    padding: 0.19rem 0rem 0.19rem 0.875rem;
                                    align-items: center;
                                    gap: 0.5rem;
                                    align-self: stretch;
                                    border-radius: 0.5rem;
                                    border: none;
                                    box-shadow: none;
                                    background: var(--base-white, #FFF);
    
                                    .select__value-container {
                                        padding: 0 !important;
                                    }
    
                                    .select__indicators {
                                        .select__indicator-separator {
                                            display: none;
                                        }
    
                                        .select__dropdown-indicator {
                                            color: #667085;
                                        }
                                    }
                                }
                            }
    
                            .selector-input:focus-visible {
                                outline: none;
                            }
                        }
                    }
                }
            }

            .add-capital-asset-form {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1.5rem;
                align-self: stretch;
                height: 100%;
                position: relative;

                .input-field-view {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.375rem;
                    align-self: stretch;

                    .input-with-label {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.375rem;
                        align-self: stretch;

                        .input-label {
                            color: var(--gray-700, #344054);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.25rem;
                        }

                        .row-input-with-icon-view {
                            display: flex;
                            padding-top: 0.19rem;
                            padding-bottom: 0.19rem;
                            padding-left: 0.875rem;
                            align-items: center;
                            gap: 0.5rem;
                            align-self: stretch;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--base-white, #FFF);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                            .input-content {
                                display: flex;
                                align-items: center;
                                gap: 0.5rem;
                                flex: 1 0 0;

                                .input-with-icon {
                                    width: 100%;
                                    border: none;
                                }

                                .input-with-icon:focus-visible {
                                    outline: none;
                                }
                            }
                        }
                    }

                    .text-input-with-label {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.375rem;
                        align-self: stretch;

                        .input-label {
                            color: var(--gray-700, #344054);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.25rem;
                        }

                        .row-input-with-icon-view {
                            display: flex;
                            padding: 0.625rem 0.875rem;
                            align-items: center;
                            gap: 0.5rem;
                            align-self: stretch;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--base-white, #FFF);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                            .input-content {
                                display: flex;
                                align-items: center;
                                gap: 0.5rem;
                                flex: 1 0 0;

                                .entity-input {
                                    width: 100%;
        
                                    .select__control {
                                        display: flex;
                                        align-items: center;
                                        gap: 0.5rem;
                                        align-self: stretch;
                                        border-radius: 0.5rem;
                                        border: none;
                                        box-shadow: none;
                                        background: var(--base-white, #FFF);
        
                                        .select__value-container {
                                            padding: 0 !important;
                                        }
        
                                        .select__indicators {
                                            .select__indicator-separator {
                                                display: none;
                                            }
        
                                            .select__dropdown-indicator {
                                                color: #667085;
                                            }
                                        }
                                    }
                                }

                                .entity-input:focus-visible {
                                    outline: none;
                                }

                                .input-with-icon {
                                    width: 100%;
                                    border: none;
                                }

                                .input-with-icon:focus-visible {
                                    outline: none;
                                }
                            }
                        }
                    }
                }

                .input-row {
                    display: flex;
                    align-items: flex-start;
                    gap: 1rem;
                    align-self: stretch;

                    .selector-input-field-view {
                        display: flex;
                        width: 15.6875rem;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.5rem;
                        align-self: stretch;
                        padding-top: 0.10rem;
    
                        .input-with-label {                              
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 0.375rem;
                            align-self: stretch;
    
                            .input-label {
                                color: var(--gray-700, #344054);
                                font-family: Inter;
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 1.25rem;
                            }
    
                            .asset-name-input {
                                width: 100%;
                                display: flex;
                                padding: 0.625rem 0.875rem;
                                align-items: center;
                                gap: 0.5rem;
                                align-self: stretch;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--white, #FFF);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            }
    
                            .asset-name-input:focus-visible {
                                outline: none;
                            }
    
    
                            .row-input-with-icon-view {
                                display: flex;
                                padding-top: 0.19rem;
                                padding-bottom: 0.19rem;
                                align-items: center;
                                gap: 0.5rem;
                                align-self: stretch;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--base-white, #FFF);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    
                                .input-content {
                                    display: flex;
                                    align-items: center;
                                    gap: 0.5rem;
                                    flex: 1 0 0;
    
                                    .entity-input {
                                        width: 100%;

                                        .custom-menu {
                                            display: flex;
                                            //justify-content: space-between;
                                            justify-content: flex-end;
                                            padding: 0.25rem 0rem;
                                            gap: 0.5rem;

                                            .add-entity-view {
                                                display: flex;
                                                padding: 0.625rem 0.75rem;
                                                justify-content: center;
                                                align-items: center;
                                                gap: 0.5rem;
                                                align-self: stretch;
                                                border-radius: 0.5rem;
                                                border: 1px solid #D0D5DD;
                                                background: var(--base-white, #FFF);
                                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                                                cursor: pointer;
                                            }

                                            .add-entity-view:hover {
                                                background-color: #f9f8f8;
                                            }

                                            .searchable-input-view {
                                                display: flex;
                                                padding: 0.5625rem 0.875rem;
                                                align-items: center;
                                                gap: 0.5rem;
                                                align-self: stretch;
                                                border-radius: 6.25rem;
                                                background: var(--search, #F1F1F1);
                                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                                                
                                                .searchable-input {
                                                    border: none;
                                                    width: 100%;
                                                    background: var(--search, #F1F1F1);
                                                }
                                                .searchable-input:focus-visible {
                                                    outline: none;
                                                }
                                                
                                            }
                                        }
            
                                        .select__control {
                                            display: flex;
                                            align-items: center;
                                            gap: 0.5rem;
                                            align-self: stretch;
                                            border-radius: 0.5rem;
                                            border: none;
                                            box-shadow: none;
                                            background: var(--base-white, #FFF);
            
                                            .select__value-container {
                                                padding: 0 !important;
                                            }
            
                                            .select__indicators {
                                                .select__indicator-separator {
                                                    display: none;
                                                }
            
                                                .select__dropdown-indicator {
                                                    color: #667085;
                                                }
                                            }
                                        }

                                        .person-icon {
                                            margin-left: 1rem;
                                            stroke: #667085;
                                        }

                                        .selected-person-icon {
                                            display: flex;
                                            width: 1.5rem;
                                            height: 1.5rem;
                                            padding: 0.25rem;
                                            justify-content: center;
                                            align-items: center;
                                            border-radius: 12.5rem;
                                            background: var(--gray-100, #F2F4F7);
                                        }
                                    }
    
                                    .entity-input:focus-visible {
                                        outline: none;
                                    }
    
                                    .input-with-icon {
                                        width: 100%;
                                        border: none;
                                    }
    
                                    .input-with-icon:focus-visible {
                                        outline: none;
                                    }
                                }
                            }

                            .existing-entity-add-new-entity {
                                width: 100%;
                                display: flex;
                                padding-top: 0.499rem;
                                padding-bottom: 0.499rem;
                                align-items: center;
                                gap: 0.5rem;
                                align-self: stretch;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--white, #FFF);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            }

                            .existing-entity-add-new-entity:focus-visible {
                                outline: none;
                            }

                            .new-entity-add-new-entity {
                                width: 100%;
                                display: flex;
                                padding-top: 0.499rem;
                                padding-bottom: 0.499rem;
                                align-items: center;
                                gap: 0.5rem;
                                align-self: stretch;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--white, #FFF);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            }

                            .new-entity-add-new-entity:focus-visible {
                                outline: none;
                            }

                            .clear-button {
                                cursor: pointer;
                            }
                        }
                    }
                    .input-field-view {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.375rem;
                        align-self: stretch;
    
                        .input-with-label {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 0.375rem;
                            align-self: stretch;
    
                            .input-label {
                                color: var(--gray-700, #344054);
                                font-family: Inter;
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 1.25rem;
                            }
    
                            .row-input-with-icon-view {
                                display: flex;
                                padding-top: 0.19rem;
                                padding-bottom: 0.19rem;
                                padding-left: 0.875rem;
                                align-items: center;
                                gap: 0.5rem;
                                align-self: stretch;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--base-white, #FFF);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    
                                .input-content {
                                    display: flex;
                                    align-items: center;
                                    gap: 0.5rem;
                                    flex: 1 0 0;
    
                                    .input-with-icon {
                                        width: 100%;
                                        border: none;
                                    }
    
                                    .input-with-icon:focus-visible {
                                        outline: none;
                                    }
                                }
                            }
                        }
                    }

                    .invested-input {
                        width: 23.125rem
                    }

                    .remove-entity-button {
                        display: flex;
                        padding: 0.625rem 0.75rem;
                        justify-content: center;
                        align-items: center;
                        gap: 0.5rem;
                        align-self: stretch;
                        border-radius: 0.5rem;
                        border: 1px solid #D0D5DD;
                        background: var(--base-white, #FFF);
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    }

                    .remove-entity-button:hover {
                        background-color: #f9f8f8;
                    }
                }

                .add-entity-button-view {
                    display: flex;
                    height: 2.75rem;
                    padding: 0.625rem 1rem;
                    justify-content: center;
                    align-items: center;
                    gap: 0.5rem;
                    align-self: stretch;
                    border-radius: 0.5rem;
                    border: 1px dashed #D0D5DD;
                    background: #FBFBFB;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    cursor: pointer;

                    .add-entity-label {
                        color: var(--gray-700, #344054);
                        font-family: Inter;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.25rem;
                    }
                }

                .add-entity-button-view:hover {
                    background-color: #f9f8f8;
                }

                .add-asset-modal-footer {
                    padding-top: 32px;
                    height: 100%;
                    justify-content: flex-end;
                }

                .entity-container {
                    max-height: 6rem;
                    position: relative;

                    .new-entities-parent-container {
                        max-height: 6rem;
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;
                        overflow-y: auto;
                        position: relative;
                    }
                    
                    .new-entities-parent-container::-webkit-scrollbar {
                        width: 5px;
                    }

                    .new-entities-parent-container::-webkit-scrollbar-thumb {
                        background-color: #bfbfbf;
                        border-radius: 6px;
                    }

                    .new-entities-parent-container::-webkit-scrollbar-thumb:hover {
                        background-color: #a0a0a0;
                    }

                    .new-entities-parent-container {
                        scrollbar-width: thin;
                        scrollbar-color: #bfbfbf #f0f0f0;
                    }

                    .new-entities-parent-container::-webkit-scrollbar-track {
                        background-color: #f0f0f0;
                    }
                }
            }

            .add-asset-modal-footer {
                display: flex;
                padding-top: 16px;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                align-self: stretch;

                .add-asset-modal-footer-content {
                    display: flex;
                    padding: 0rem 0rem 1.5rem 1.5rem;
                    align-items: center;
                    gap: 0.75rem;
                    align-self: stretch;

                    .add-asset-modal-footer-buttons {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 0.75rem;
                        flex: 1 0 0;

                        .cancel-asset-button {
                            display: flex;
                            padding: 0.625rem 1.125rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--base-white, #FFF);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color: var(--gray-700, #344054);
                            font-family: Inter;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.5rem;
                        }

                        .cancel-asset-button:hover {
                            background-color: #f9f8f8;
                        }

                        .back-asset-button {
                            display: flex;
                            padding: 0.625rem 1.125rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--base-white, #FFF);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color: var(--gray-700, #344054);
                            font-family: Inter;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.5rem;
                        }

                        .back-asset-button:hover {
                            background-color: #f9f8f8;
                        }

                        .disable-next-asset-button {
                            display: flex;
                            width: 6.1875rem;
                            padding: 0.625rem 1.125rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--gray-300, #D0D5DD);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color: var(--base-white, #FFF);
                            font-family: Inter;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.5rem;    
                        }

                        .enable-next-asset-button {
                            display: flex;
                            width: 6.1875rem;
                            padding: 0.625rem 1.125rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.5rem;
                            border: 1px solid var(--primary-600, #000);
                            background: var(--primary-600, #000);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color: var(--base-white, #FFF);
                            font-family: Inter;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.5rem;
                        }

                        .enable-next-asset-button:hover {
                            background-color: #3c3a3a;
                        }

                        .disable-add-asset-button {
                            display: flex;
                            width: 8.25rem;
                            padding: 0.625rem 1.125rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--gray-300, #D0D5DD);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color: var(--base-white, #FFF);
                            font-family: Inter;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.5rem;    
                        }

                        .enable-add-asset-button {
                            display: flex;
                            width: 8.25rem;
                            padding: 0.625rem 1.125rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.5rem;
                            border: 1px solid var(--primary-600, #000);
                            background: var(--primary-600, #000);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color: var(--base-white, #FFF);
                            font-family: Inter;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.5rem;
                        }

                        .enable-add-asset-button:hover {
                            background-color: #3c3a3a;
                        }
                    }

                    .error {
                        color: #E66464;
                        display: flex;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .add-asset-modal::-webkit-scrollbar {
        width: 5px;
    }

    .add-asset-modal::-webkit-scrollbar-thumb {
        background-color: #bfbfbf;
        border-radius: 600px;
    }

    .add-asset-modal::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a0;
    }

    .add-asset-modal {
        scrollbar-width: thin;
        scrollbar-color: #bfbfbf #f0f0f0;
    }

    .add-asset-modal::-webkit-scrollbar-track {
        background-color: #f0f0f0;
        border-radius: 0 20.75rem 20.75rem 0; /* Rounded on right side */
    }

    .add-asset-modal:focus-visible {
        outline: none;
    }
}