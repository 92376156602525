.asset-detail-header-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;

  .asset-detail-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;

    .page-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      align-self: stretch;

      .breadcumbs {
        display: flex;
        align-items: center;

        .dashboard-icon-view {
          display: flex;
          padding: 0.25rem 0.25rem 0.25rem 0rem;
          align-items: flex-start;
          border-radius: 0.375rem;
        }

        .tabs {
          display: flex;
          align-items: center;
          gap: 0.25rem;

          .text-view {
            display: flex;
            padding: 0.25rem 0.125rem;
            justify-content: center;
            align-items: center;
            border-radius: 0.375rem;

            .text {
              color: var(--gray-600, #475467);
              font-family: Inter;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 500;
              line-height: 1.25rem;
            }
          }

          .bank-account-text-view {
            display: flex;
            padding: 0.25rem 0.5rem;
            justify-content: center;
            align-items: center;
            border-radius: 0.375rem;
            background: var(--breadcrumbs-on, #F2F2F2);

            .text {
              color: var(--gray-700, #344054);
              font-family: Inter;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 600;
              line-height: 1.25rem;
            }
          }
        }
      }

      .content {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;

        .selector-view {
          display: flex;
          align-items: center;
          gap: 0.625rem;
          flex: 1 0 0;
          cursor: pointer;

          .selector-input {
            width: 100%;

            .menu-container {
              display: flex;
              padding-top: 0.75rem;
              padding-right: 1.2rem;
              padding-bottom: 0.75rem;
              padding-left: 1.2rem;
              flex-direction: column;
              gap: 0.5rem;
              align-self: stretch;

              .custom-menu {
                display: flex;
                justify-content: space-between;
                padding: 0rem !important;
                gap: 0.5rem;

                .searchable-input-view {
                  display: flex;
                  padding: 0.5625rem 0.875rem;
                  align-items: center;
                  gap: 0.5rem;
                  align-self: stretch;
                  border-radius: 6.25rem;
                  background: var(--search, #F1F1F1);
                  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                  .searchable-input {
                    border: none;
                    width: 100%;
                    background: var(--search, #F1F1F1);
                  }

                  .searchable-input:focus-visible {
                    outline: none;
                  }

                }
              }

              .select__option {
                border-radius: 0.375rem;
                padding-left: 1rem;
                padding-right: 1rem;
              }
            }

            .select__control {
              min-width: 17rem !important;
              display: flex;
              align-items: center;
              gap: 0.5rem;
              align-self: stretch;
              border-radius: 0.5rem;
              border: none;
              box-shadow: none;
              background: #F9FAFB;

              .select__value-container {
                padding: 0 !important;
              }

              .select__indicators {
                flex: 0 0 calc(40% - 0.25rem);

                .select__indicator-separator {
                  display: none;
                }

                .select__dropdown-indicator {
                  color: #667085;
                }

                .select__indicator {
                  color: #000000;
                }
              }

              .select__value-container {
                flex: 0 0 calc(60% - 0.25rem);

                .select__single-value {
                  color: var(--gray-900, #101828);
                  font-family: Inter;
                  font-size: 1.5rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 2.375rem;
                }
              }
            }

            .person-icon {
              margin-left: 1rem;
              stroke: #667085;
            }

            .selected-person-icon {
              display: flex;
              width: 1.5rem;
              height: 1.5rem;
              padding: 0.25rem;
              justify-content: center;
              align-items: center;
              border-radius: 12.5rem;
              background: var(--gray-100, #F2F4F7);
            }
          }

          .selector-input:focus-visible {
            outline: none;
          }
        }
      }
    }

    .tabs-container {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      align-self: stretch;
      justify-content: space-between;

      .asset-details-filters {
        display: flex;
        align-items: center;
        column-gap: 1rem;
      }
      .floating-selector-view {
        min-width: auto;

        .select-filter {
          .select__control {
            display: flex;
            width: 10.5625rem;
            height: 2.75rem;
            max-width: 12.5rem;
            border-radius: 0.5rem;
            border: 1px solid var(--gray-300, #D0D5DD);
            background: var(--white, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            padding-right: 7px;

            .select__value-container {
              margin-top: 0.5rem;
              color: #101828;
              font-weight: 500;
              font-family: Inter;

              .select__placeholder {
                color: #101828;
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
              }
            }

            .select__indicators {
              .select__indicator-separator {
                display: none;
              }

              .select__dropdown-indicator {
                color: #667085;
              }
            }
          }
        }
      }

      .button-groups {
        display: flex;
        align-items: flex-start;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        .tab-button:nth-of-type(1) {
          display: flex;
          padding: 0.625rem 1rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          border: 1px solid var(--gray-300, #D0D5DD);
          border-right: 1px solid var(--gray-300, #D0D5DD);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          background: var(--base-white, #FFF);
          color: var(--gray-800, #1D2939);
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }

        .tab-button:nth-of-type(2) {
          display: flex;
          padding: 0.625rem 1rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border: 1px solid var(--gray-300, #D0D5DD);
          border-left: none;
          border-right: 1px solid var(--gray-300, #D0D5DD);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          background: var(--base-white, #FFF);
          color: var(--gray-800, #1D2939);
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }

        .tab-button:nth-of-type(3) {
          display: flex;
          padding: 0.625rem 1rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border: 1px solid var(--gray-300, #D0D5DD);
          border-left: none;
          border-right: 1px solid var(--gray-300, #D0D5DD);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          background: var(--base-white, #FFF);
          color: var(--gray-800, #1D2939);
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }

        .tab-button:nth-of-type(4) {
          display: flex;
          padding: 0.625rem 1rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          // border-top-right-radius: 0.5rem;
          // border-bottom-right-radius: 0.5rem;
          border: 1px solid var(--gray-300, #D0D5DD);
          border-left: none;
          border-right: 1px solid var(--gray-300, #D0D5DD);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          background: var(--base-white, #FFF);
          color: var(--gray-800, #1D2939);
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }

        .tab-button:nth-of-type(5) {
          display: flex;
          padding: 0.625rem 1rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          border: 1px solid var(--gray-300, #D0D5DD);
          border-left: none;
          border-right: 1px solid var(--gray-300, #D0D5DD);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          background: var(--base-white, #FFF);
          color: var(--gray-800, #1D2939);
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }

        .tab-button:hover {
          background: var(--gray-50, #F9FAFB);
        }

        .active {
          background: var(--gray-50, #F9FAFB) !important;
        }
      }

      .edit-asset-summary-button {
        display: flex;
        height: 2.375rem;
        padding: 0.625rem 1rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        border-radius: 0.5rem;
        border: 1px solid #000;
        background: var(--primary-600, #000);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--base-white, #FFF);
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
      }

      .edit-asset-summary-button:hover {
        background-color: #3c3a3a;
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.625rem;
        flex: 1 0 0;

        .select-date-button {
          display: flex;
          padding: 0.625rem 1rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border-radius: 0.5rem;
          border: 1px solid var(--gray-300, #D0D5DD);
          background: var(--base-white, #FFF);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: var(--gray-500, var(--Icon, #667085));
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }

        .filters-button {
          display: flex;
          padding: 0.625rem 1rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border-radius: 0.5rem;
          border: 1px solid var(--gray-300, #D0D5DD);
          background: var(--base-white, #FFF);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: var(--gray-700, #344054);
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }

        .add-event-button {
          display: flex;
          height: 2.375rem;
          padding: 0.625rem 1rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border-radius: 0.5rem;
          border: 1px solid #000;
          background: var(--primary-600, #000);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: var(--base-white, var(--white, #FFF));
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }

        .add-event-button:hover {
          background-color: #3c3a3a;
        }
      }
    }

    .frame-1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5rem;
      align-self: stretch;

      .frame-2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;

        .section {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1.5rem;
          align-self: stretch;

          .section-container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 1rem;
            align-self: stretch;

            .metric {
              display: flex;
              align-items: center;
              gap: 1rem;
              align-self: stretch;
              width: 100%;

              .metric-item {
                display: flex;
                padding: 1.5rem;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.5rem;
                flex: 1 0 0;
                border-radius: 0.75rem;
                border: 1px solid var(--gray-200, #EAECF0);
                background: var(--base-white, #FFF);
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
                width: 16.375rem;

                .metric-header-text {
                  align-self: stretch;
                  color: var(--gray-600, #475467);
                  font-family: Inter;
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.25rem;
                }

                .metric-header-value {
                  flex: 1 0 0;
                  color: var(--gray-900, #101828);
                  font-family: Inter;
                  font-size: 1.5rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 1.75rem;
                  letter-spacing: -0.03rem;
                }
              }
            }
          }
        }

        .frame-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1.5rem;
          align-self: stretch;
        }
      }

      .section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        align-self: stretch;

        .section-container {
          width: 100%;
          display: flex;
          //flex-direction: row;
          align-items: flex-start;
          gap: 1.5rem;
          align-self: stretch;

          .section-container-card {
            width: 50%;
            display: flex;
            height: 30rem;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            border-radius: 0.75rem;
            border: 1px solid var(--gray-200, #EAECF0);
            background: var(--white, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

            .section-container-card-content {
              display: flex;
              padding: 1.5rem;
              flex-direction: column;
              align-items: flex-start;
              gap: 2rem;
              flex: 1 0 0;
              align-self: stretch;

              .graph-card {
                width: 100%;

                .content {
                  .content-header {
                    .content {
                      .text-view {
                        .text {
                          align-self: stretch;
                          color: var(--gray-900, #101828);
                          font-family: Inter;
                          font-size: 1rem;
                          font-style: normal;
                          font-weight: 600;
                          line-height: 1.5rem;
                        }
                      }
                    }
                  }
                }
              }

              .section-container-card-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1.25rem;
                align-self: stretch;

                .graph-header-text {
                  align-self: stretch;
                  color: var(--gray-900, #101828);
                  font-family: Inter;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 1.5rem;
                }

                .select-date-view {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 0.5rem;

                  .select-date-button {
                    display: flex;
                    padding: 0.625rem 1rem;
                    justify-content: center;
                    align-items: center;
                    gap: 0.5rem;
                    border-radius: 0.5rem;
                    border: 1px solid var(--gray-300, #D0D5DD);
                    background: var(--base-white, #FFF);
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    color: var(--gray-500, var(--Icon, #667085));
                    font-family: Inter;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.25rem;
                  }
                }
              }

              .overview-chart-view {
                width: 100%;

                .overview-chart {
                  .apexcharts-canvas {
                    svg {
                      foreignObject {
                        .apexcharts-legend {
                          .apexcharts-legend-series {
                            .apexcharts-legend-text {
                              color: var(--gray-600, #475467) !important;
                              font-family: Inter !important;
                              font-size: 0.875rem !important;
                              font-style: normal !important;
                              font-weight: 400 !important;
                              line-height: 1.25rem !important;
                            }
                          }
                        }
                      }

                      .apexcharts-yaxis {
                        .apexcharts-yaxis-texts-g {
                          text {
                            fill: #344054 !important;
                            text-align: right !important;
                            font-family: Inter !important;
                            font-size: 0.75rem !important;
                            font-style: normal !important;
                            font-weight: 400 !important;
                            line-height: 1.125rem !important;
                          }
                        }

                        .apexcharts-yaxis-title {
                          text {
                            fill: #475467 !important;
                            text-align: center !important;
                            font-family: Inter !important;
                            font-size: 0.75rem !important;
                            font-style: normal !important;
                            font-weight: 500 !important;
                            line-height: 1.125rem !important;
                          }
                        }
                      }

                      .apexcharts-xaxis {
                        .apexcharts-xaxis-texts-g {
                          text {
                            fill: #344054 !important;
                            text-align: right !important;
                            font-family: Inter !important;
                            font-size: 0.75rem !important;
                            font-style: normal !important;
                            font-weight: 400 !important;
                            line-height: 1.125rem !important;
                          }
                        }

                        .apexcharts-xaxis-title {
                          text {
                            fill: #475467 !important;
                            text-align: center !important;
                            font-family: Inter !important;
                            font-size: 0.75rem !important;
                            font-style: normal !important;
                            font-weight: 500 !important;
                            line-height: 1.125rem !important;
                          }
                        }
                      }
                    }
                  }

                }
              }

            }
          }
        }
      }
    }
  }
}

.custom-select-container {
  position: relative;

  .asset-summary-event-splits-filter-entity {
    .select__control {
      display: flex;
      width: 10.5625rem;
      height: 2.75rem;
      max-width: 12.5rem;
      border-radius: 0.5rem;
      border: 1px solid var(--gray-300, #D0D5DD);
      background: var(--white, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding-right: 7px;

      .select__value-container {
        margin-top: 0.5rem;
        color: #101828;
        font-weight: 500;
        font-family: Inter;

        .select__placeholder {
          color: #101828;
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.5rem;
        }
      }

      .select__indicators {
        .select__indicator-separator {
          display: none;
        }

        .select__dropdown-indicator {
          color: #667085;
        }
      }
    }
  }
}

.floating-label {
  position: absolute;
  top: 10px; /* Adjust the top position as needed */
  left: 10px; /* Adjust the left position as needed */
  pointer-events: none;
  transition: top 0.2s, font-size 0.2s;
  color: var(--gray-600, #475467);
}

.label-floating {
  top: 2px; /* Adjust the top position when the label is floating */
  font-size: 12px; /* Adjust the font size when the label is floating */
  left: 17px;
}

@media (max-width: 1100px) {
 .button-groups {
   height: 62px
 }
 .tab-button {
   height: 100%;
 }
 //.select__control {
 //  height: 62px
 //}
}

@media (max-width: 1600px) {
  .section-container {
    flex-direction: column;
  }
}

@media (max-width: 1600px) {
  .section-container-card {
    width: 100% !important;
  }
}

//@media (min-width: 1600px) {
//  .section-container-card {
//    width: 50%;
//  }
//}
