.events-summary-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    max-height: 55rem;

    border-radius: 0.75rem !important;
    border: 1px solid var(--gray-200, #EAECF0);
    background: var(--base-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10) !important;

    table {
        background: var(--base-white, #FFF);

        thead {
            border-bottom: 1px solid #EAECF0;

            tr {

                th {
                    font-family: Inter;
                    font-size: 0.75rem;
                    font-weight: 500;
                    color: #475467;
                }

                .share-price-column {
                    display: flex;
                    gap: 0.3rem;
                }
            }
        }
    }

    table {

        tbody {

            tr {

                td {
                    height: 4.5rem;
                    padding: 1rem 1.5rem;
                    font-family: Inter;
                    font-size: 0.875rem;
                    font-weight: 400;
                    color: #475467;

                    .disabled-file {
                        opacity: 0.3;
                    }

                    .file-icon {
                        cursor: pointer;
                    }

                    .trash-icon {
                        cursor: pointer;
                    }

                    .edit-icon {
                        cursor: pointer;
                    }
                }

                td:nth-of-type(1) {
                    color: var(--gray-900, #101828);
                    font-family: Inter;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.25rem;
                }
            }
        }
    }
}