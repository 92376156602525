.account-setting-title-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;

    .account-setting-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.625rem;
        align-self: stretch;

        .page-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            align-self: stretch;

            .content {
                display: flex;
                align-items: center;
                gap: 1rem;
                align-self: stretch;

                .MuiTypography-root {
                    align-self: stretch;
                    color: var(--gray-900, #101828);
                    font-family: Inter;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 2.375rem;
                }
            }
        }
    }
}

.account-setting-header-container {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;

    .button-group {
        display: flex;
        align-items: flex-start;
        border-radius: 0.5rem;
        border: 1px solid var(--gray-300, #D0D5DD);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        .profile-tab-button {
            display: flex;
            padding: 0.625rem 1rem;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            border-right: 1px solid var(--gray-300, #D0D5DD);
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
            background: var(--base-white, #FFF);
            border-left: none;
            border-top: none;
            border-bottom: none;


            // text
            color: var(--gray-800, #1D2939);
            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem;
        }

        .profile-tab-button:hover {
            background: var(--gray-50, #F9FAFB);
        }

        .profile-tab-active {
            background: var(--gray-50, #F9FAFB);
        }

        .change-password-tab-button {
            display: flex;
            padding: 0.625rem 1rem;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            background: var(--base-white, #FFF);
            border: none;

            // text
            color: var(--gray-800, #1D2939);
            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem;
        }

        .change-password-tab-button:hover {
            background: var(--gray-50, #F9FAFB);
        }

        .change-password-tab-active {
            background: var(--gray-50, #F9FAFB);
        }
    }
    
    .action-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.625rem;
        flex: 1 0 0;

        .logout-button {
            display: flex;
            padding: 0.625rem 1rem;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            border-radius: 0.5rem;
            border: 1px solid var(--gray-300, #D0D5DD);
            background: var(--base-white, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

            //text
            color: var(--gray-700, #344054);
            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem;
        }

        .logout-button:hover {
            background: var(--gray-50, #F9FAFB);
        }
    }
}

.account-setting-profile-form-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;

    .account-setting-profile-form-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        align-self: stretch;

        .account-setting-profile-form {
            display: flex;
            padding: 1.75rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.5rem;
            align-self: stretch;
            border-radius: 0.75rem;
            border: 1px solid var(--section-line, #EAECF0);
            background: var(--white, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

            .account-setting-profile-form-header {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1.25rem;
                align-self: stretch;

                .account-setting-profile-form-header-content {
                    display: flex;
                    align-items: flex-start;
                    gap: 1rem;
                    align-self: stretch;

                    .intro {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 0.25rem;
                        flex: 1 0 0;
                        align-self: stretch;

                        .title {
                            align-self: stretch;
                            color: var(--gray-900, #101828);
                            font-family: Inter;
                            font-size: 1.125rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.75rem;
                        }
    
                        .info {
                            align-self: stretch;
                            color: var(--gray-600, #475467);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.25rem;
                        }
                    }

                    .action-buttons {
                        display: flex;
                        align-items: center;
                        gap: 0.75rem;

                        .cancel {
                            display: flex;
                            padding: 0.625rem 1rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--base-white, #FFF);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color: var(--gray-700, #344054);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.25rem;
                        }

                        .cancel:hover {
                            background: var(--gray-50, #F9FAFB);
                        }

                        .org-save {
                            display: flex;
                            padding: 0.625rem 1rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--base-white, #FFF);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color: var(--gray-700, #344054);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.25rem;
                        }




                        .disable-save-button {
                            display: flex;
                            width: 5.3125rem;
                            padding: 0.625rem 1rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.5rem;
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color: var(--base-white, #FFF);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.25rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--gray-300, #D0D5DD);
                        }

                        .save {
                            display: flex;
                            width: 5.3125rem;
                            padding: 0.625rem 1rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.5rem;
                            border: 1px solid var(--black, #000);
                            background: var(--black, #000);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color: var(--base-white, #FFF);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.25rem;
                        }

                        .save:hover {
                            background-color: #3c3a3a;
                        }
                    }
                }

                .divider {
                    border-top: 1px solid #EAECF0;
                    height: 0.0625rem;
                    align-self: stretch;
                }
            }

            .account-setting-profile-form-body {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1.25rem;
                align-self: stretch;

                .name-input-content {
                    display: flex;
                    align-items: flex-start;
                    gap: 2rem;
                    align-self: stretch;

                    .label {
                        width: 17.5rem;
                        color: var(--gray-700, #344054);
                        font-family: Inter;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.25rem;
                    }

                    .input-fields {
                        display: flex;
                        align-items: flex-start;
                        gap: 1.5rem;
                        flex: 1 0 0;

                        .input-field {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 0.375rem;
                            flex: 1 0 0;

                            .input {
                                display: flex;
                                padding: 0.625rem 0.875rem;
                                align-items: center;
                                gap: 0.5rem;
                                align-self: stretch;
                                border-radius: 0.5rem;
                                border: 1px solid var(--gray-300, #D0D5DD);
                                background: var(--base-white, #FFF);
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                                //text
                                color: var(--gray-900, #101828);
                                font-family: Inter;
                                font-size: 1rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 1.5rem;
                            }
                            .input:focus-visible {
                                outline: none;
                            }
                        }
                    }
                }

                .email-input-content {
                    display: flex;
                    align-items: flex-start;
                    gap: 2rem;
                    align-self: stretch;

                    .label {
                        width: 17.5rem;
                        color: var(--gray-700, #344054);
                        font-family: Inter;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.25rem;
                    }

                    .input-with-icon-view {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.375rem;
                        flex: 1 0 0;

                        .input-content {
                            display: flex;
                            padding: 0.625rem 0.875rem;
                            align-items: center;
                            gap: 0.5rem;
                            align-self: stretch;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--base-white, #FFF);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                            .input-with-icon {
                                width: 100%;
                                border: none;
                            }

                            .input-with-icon:focus-visible {
                                outline: none;
                            }
                        }
                    }
                }

                .error {
                    color: #E66464;
                    display: flex;
                    font-weight: 600;
                }
                
                .divider {
                    border-top: 1px solid #EAECF0;
                    height: 0.0625rem;
                    align-self: stretch;
                }
            }
        }
    }
}


.account-setting-password-form-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;

    .account-setting-password-form-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        align-self: stretch;

        .account-setting-password-form {
            display: flex;
            padding: 1.75rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.5rem;
            align-self: stretch;
            border-radius: 0.75rem;
            border: 1px solid var(--section-line, #EAECF0);
            background: var(--white, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

            .account-setting-password-form-header {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1.25rem;
                align-self: stretch;

                .account-setting-password-form-header-content {
                    display: flex;
                    align-items: flex-start;
                    gap: 1rem;
                    align-self: stretch;

                    .intro {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 0.25rem;
                        flex: 1 0 0;
                        align-self: stretch;

                        .title {
                            align-self: stretch;
                            color: var(--gray-900, #101828);
                            font-family: Inter;
                            font-size: 1.125rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.75rem;
                        }
    
                        .info {
                            align-self: stretch;
                            color: var(--gray-600, #475467);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.25rem;
                        }
                    }

                    .action-buttons {
                        display: flex;
                        align-items: center;
                        gap: 0.75rem;

                        .cancel {
                            display: flex;
                            padding: 0.625rem 1rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--base-white, #FFF);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color: var(--gray-700, #344054);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.25rem;
                        }

                        .cancel:hover {
                            background: var(--gray-50, #F9FAFB);
                        }

                        .disable-save-button {
                            display: flex;
                            width: 5.3125rem;
                            padding: 0.625rem 1rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.5rem;
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color: var(--base-white, #FFF);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.25rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--gray-300, #D0D5DD);
                        }

                        .save {
                            display: flex;
                            width: 5.3125rem;
                            padding: 0.625rem 1rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.5rem;
                            background: var(--black, #000);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            color: var(--base-white, #FFF);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.25rem;
                        }

                        .save:hover {
                            background-color: #3c3a3a;
                        }
                    }
                }

                .divider {
                    border-top: 1px solid #EAECF0;
                    height: 0.0625rem;
                    align-self: stretch;
                }
            }

            .account-setting-password-form-body {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1.25rem;
                align-self: stretch;

                .input-content {
                    display: flex;
                    align-items: flex-start;
                    gap: 2rem;
                    align-self: stretch;

                    .label {
                        width: 17.5rem;
                        color: var(--gray-700, #344054);
                        font-family: Inter;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.25rem;
                    }

                    .input-field {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.375rem;
                        flex: 1 0 0;
                        align-self: stretch;

                        .input {
                            display: flex;
                            padding: 0.625rem 0.875rem;
                            align-items: center;
                            gap: 0.5rem;
                            align-self: stretch;
                            border-radius: 0.5rem;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--base-white, #FFF);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                            //text
                            color: var(--gray-900, #101828);
                            font-family: Inter;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.5rem;
                        }

                        input::placeholder{
                            font-size: 2.5rem;
                            line-height: 4;
                        }

                        input::-moz-placeholder {
                            font-size: 2.5rem;
                            line-height: 4;
                        }

                        .input:focus-visible {
                            outline: none;
                        }

                        .new-password-info-message {
                            align-self: stretch;
                            color: var(--gray-600, #475467);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.25rem;
                        }
                    }
                }

                .error {
                    color: #E66464;
                    display: flex;
                    font-weight: 600;
                }

                .divider {
                    border-top: 1px solid #EAECF0;
                    height: 0.0625rem;
                    align-self: stretch;
                }
            }
        }
    }
}